import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Marquee from 'react-fast-marquee';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Desktopthree() {
  useEffect(() => {
    gsap.fromTo(
      '.fotx',
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: '.fotx',
          start: 'top 80%',
          end: 'bottom 20%',
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div className='footer'>
      <Grid container>
        <Grid item md={12} xs={12} lg={12} className='centerall'>
          <Marquee className='backofit'>
            <p className='fotx'>
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
              &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp;
              &nbsp;&nbsp; Buy $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy
              $WOJAK or Cry &nbsp;&nbsp; &nbsp;&nbsp; Buy $WOJAK or Cry
            </p>
          </Marquee>
        </Grid>
      </Grid>
    </div>
  );
}

export default Desktopthree;
