import React from 'react'
import { Grid } from '@mui/material'
import anos from './woah.png'
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import c1 from './phantom.png'
import c2 from './solana.png'
import c3 from './rads.webp'
import c4 from './woj.jpeg'
import wojak from './wo.jpeg'
import instagram from './instagram.png'
import ch1 from './c1.png'
import ch2 from './c2.png'
import button from './bwo.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import dextool from './dextool.png'
import dexscreener from './dexscreener.png'
function Desktop() {
  return (
    <div className='container'>
      <br/>
          <Grid container className='backall'>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
         
      
      
      <a href="https://www.dextools.io/app/en/solana/pair-explorer/5RowEsq8BwD33Ur7msMPuaBUSh9JZs2EZkEdEa3VHjCi?t=1716437823290" class="matrix-text "
          style={{textDecoration:"none"}}
          
          >
      Dextools</a>
         
      &nbsp;&nbsp;&nbsp;
         <a href="https://x.com/WojakOnSolana69"  class="matrix-text "
          style={{textDecoration:"none"}}
          
          > Twitter</a>      &nbsp;&nbsp;&nbsp;
             <a href="https://t.me/WojakOnSolana69"  class="matrix-text "
          
          > Telegram</a>

&nbsp;&nbsp;&nbsp;
<a href="https://raydium.io/swap/?inputMint=sol&outputMint=9qDzfUTi88EJKJcUCqtQFASzCnp4aefCoe1YEfox7YAy"  class="matrix-text "
          style={{textDecoration:"none"}}
          target="_ blank"
          > Buy </a>
         </Grid>
          </Grid>
       
          <br/>
          <Grid container>
          <Grid item md={6} xs={12} lg={6} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
         <Grid item md={6} xs={12} lg={6} className='centerall'>
          <br/>   
         <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_text">$WOJAK</h1>
         
         <p className='anon_p'> 
       CA : 9qDzfUTi88EJKJcUCqtQFASzCnp4aefCoe1YEfox7YAy</p>
       <div  className='centerall'>
          
      
    
         
      &nbsp;&nbsp;
         <a href="https://www.dextools.io/app/en/solana/pair-explorer/5RowEsq8BwD33Ur7msMPuaBUSh9JZs2EZkEdEa3VHjCi?t=1716437823290"  class="matrix-text "
          style={{textDecoration:"none"}}
          
          >   <img src={dextool} style={{width:"100px"}}/></a>&nbsp;&nbsp;
             
             <a href="https://dexscreener.com/solana/5rowesq8bwd33ur7msmpuabush9jzs2ezkedea3vhjci"  class="matrix-text "
          
          >   <img src={dexscreener} style={{width:"100px"}}/></a>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

         </div>
        <a href='https://raydium.io/swap/?inputMint=sol&outputMint=9qDzfUTi88EJKJcUCqtQFASzCnp4aefCoe1YEfox7YAy'>

          <img src={button}   style={{width:"300px"}}/>
        </a>


         </Grid>
     
          </Grid>

          <br/>  <br/>


          <Grid container>
          
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 

         " WOJAK, the worlds best meme, now on SOL"</h1>
         </Grid>

       
          </Grid>

<img src={wojak} style={{width:"100%"}}/>

          <Grid container spacing={2}>
          
        
 





           </Grid>
          <br/><br/>   
          <Grid container spacing={2}>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           <h1 style={{fontWeight:"900"}} class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 
 
        How to Buy</h1>
          </Grid>
 
        <Grid item md={6} xs={12} lg={6} className='centerall'>

        <div class="">
        <br/>
        <div className='container'>
        <h4 className='htb_h'>Create a Wallet</h4>
          <div className='centerall'> <img src={c1} style={{width:"50px"}} /></div>
          <p className='htb_hp'>Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app.</p>
         

        </div>
          
</div>
        </Grid>

        <Grid item md={6} xs={12} lg={6} className='centerall'>

<div class="">
<br/>
<div className='container'>
<h4 className='htb_h'>
Get Some SOL</h4>
  <div className='centerall'> <img src={c2} style={{width:"50px"}} /></div>
  <p className='htb_hp'>

  Have SOL in your wallet to switch to $WOJAK. If you don’t have any SOL, you can buy SOL from an exchange or cross chain swap and send it to your wallet.
  </p>
 

</div>
  
</div>
</Grid>

<Grid item md={6} xs={12} lg={6} className='centerall'>

<div class="">
<br/>
<div className='container'>
<h4 className='htb_h'>
Go to Raydium</h4>
  <div className='centerall'> <img src={c3} style={{width:"50px"}} /></div>
  <p className='htb_hp'>

  Connect to Raydium . Go raydium.io  in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $WOJAK token address into Raydium and confirm the swap. When Phantom prompts you for a wallet signature, sign.
  </p>
 

</div>
  
</div>
</Grid>


<Grid item md={6} xs={12} lg={6} className='centerall'>

<div class="">
<br/>
<div className='container'>
<h4 className='htb_h'>
Switch SOL for WOJAK</h4>
  <div className='centerall'> <img src={c4} style={{width:"60px" , border:"2px solid #000" , borderRadius:"100px"}} /></div>
  <br/>
  <p className='htb_hp'>

  Now paste WOJAK Contract Address on Raydium and swap your Sol to $WOJAK
  </p>
 

</div>
  
</div>
</Grid>
           </Grid>
          
<br/><br/>
           <Grid container>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
           <h1 class="opacity-bg  text-4xl md:text-5xl mb-4 text-center anos_textg"> 
 
         Join $WOJAK</h1>

         <br/>


         <div className='container centerall'>
          <a href='https://x.com/WojakOnSolana69'>
          <img src={twitter} style={{width:"80px"}}/>
            </a>
            &nbsp;&nbsp;
          <a href='https://t.me/WojakOnSolana69'> 
          <img src={telegram} style={{width:"80px"}}/>
          </a>
          &nbsp;&nbsp;
          <a href='
https://www.instagram.com/wojaksolana?igsh=bmJvamVuZWU1ZW4w'> 
          <img src={instagram} style={{width:"80px"}}/>
          </a>
         </div>
          </Grid>
 
        
           </Grid>
 <br/>
           <p className='center_diss'>Disclaimer
            <br/><br/>
             The content provided on this website, including information about $WOJAK (WOJAK Coin), is intended for informational purposes only and should not be construed as financial advice. Investing in cryptocurrencies involves inherent risks, including the potential loss of principal. </p>
<br/>  <br/>
    </div>
  )
}

export default Desktop